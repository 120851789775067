<template>
  <v-dialog v-model="dialog" width="600" @click:outside="close">
    <template v-if="!invisible" v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="secondary" rounded small>
        {{ $t("common.details") }}
      </v-btn>
    </template>

    <v-card v-if="dialog" class="survey-user-modal">
      <v-card-title>
        <h3>{{ $t("survey.betaTesting") }}</h3>
        <v-spacer />
        <close-button @click="close" />
      </v-card-title>

      <v-divider />

      <v-card-text>
        <div v-for="item in metaData" :key="item.text" class="d-flex mb-3">
          <div class="font-weight-bold" style="width: 100px">
            {{ item.text }}:
          </div>
          <div>{{ item.value }}</div>
        </div>

        <v-divider class="my-5" />

        <ol>
          <div v-for="(item, i) in items" :key="i" class="mb-4">
            <li class="font-weight-bold">{{ item.question }}</li>
            <span class="primary--text font-weight-bold">A:</span>
            {{ item.answer }}
          </div>
        </ol>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    report: Object,
    invisible: Boolean,
  },

  data: () => ({
    dialog: false,
  }),

  computed: {
    metaData() {
      return [
        {
          text: this.$t("common.date"),
          value: this.$utils.formatDatetime(this.report.created_at),
        },
        {
          text: this.$t("common.user"),
          value: this.report.user.username,
        },
      ];
    },

    items() {
      return [
        ...this.report.survey.map((i) => ({
          question: i.question.question,
          answer: i.answer.answer,
        })),
        {
          question: this.$t("survey.comment"),
          answer: this.report.suggestion,
        },
      ];
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
