<template>
  <v-menu
    v-model="menu"
    bottom
    left
    offset-y
    :close-on-content-click="false"
    content-class="menu-modal"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
    </template>

    <v-card class="d-flex flex-column mt-1 rounded overflow-hidden">
      <!-- Export -->
      <v-btn
        color="secondary"
        small
        class="justify-start"
        :loading="$loading('surveys/downloadReport') === report.id"
        @click="download"
      >
        <v-icon left>mdi-download</v-icon>
        {{ $t("survey.download") }}
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    report: Object,
  },

  data: () => ({
    menu: false,
  }),

  methods: {
    download() {
      this.$store
        .dispatch("surveys/downloadReport", this.report.id)
        .then(() => (this.menu = false))
        .catch(() => this.$toast.error("Error while download survey report."));
    },
  },
};
</script>

<style></style>
