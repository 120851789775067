<template>
  <div class="page">
    <component :is="activeComponent" @next="onNext" v-bind="props"></component>
  </div>
</template>

<script>
export default {
  metaInfo: { title: "Survey" },

  components: {
    Survey: () => import("@/components/app/survey/Survey.vue"),
    SurveyCompleted: () =>
      import("@/components/app/survey/SurveyCompleted.vue"),
    SurveyLanding: () => import("@/components/app/survey/SurveyLanding.vue"),
  },

  data: () => ({
    activeComponent: "SurveyLanding",
    props: {},
  }),

  methods: {
    onNext(e = {}) {
      this.props = e;

      switch (this.activeComponent) {
        case "SurveyLanding":
          this.activeComponent = "Survey";
          break;

        case "Survey":
          this.activeComponent = "SurveyCompleted";
          break;
      }
    },
  },
};
</script>
