<template>
  <column-layout class="page" sticky-sides noRight>
    <!-- Left -->
    <template v-slot:left>
      <AdminMenu />
    </template>

    <!-- Main -->
    <template>
      <div class="widget">
        <label class="label">
          {{ $t("survey.betaTestingResults") }}
        </label>

        <div class="card pa">
          <div class="stat-boxes">
            <StatBox
              v-for="(box, i) in boxes"
              :key="i"
              :label="box.label"
              :value="box.value"
              :loading="false"
              small
            />
          </div>

          <SurveyTable class="mt-10" />
        </div>
      </div>
    </template>
  </column-layout>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu.vue";
import StatBox from "@/components/admin/StatBox.vue";
import SurveyTable from "@/components/admin/survey/SurveyTable.vue";

export default {
  metaInfo: { title: "Survey" },

  components: { AdminMenu, StatBox, SurveyTable },

  data: () => ({
    stats: {
      total_surveys: 100,
      average_impressions: 4.3,
      most_used_device: "Windows",
    },
  }),

  computed: {
    boxes() {
      const boxes = [];

      for (const property in this.stats) {
        boxes.push({
          label: this.$t("survey.stats." + property),
          value: this.stats[property],
        });
      }

      return boxes;
    },
  },

  created() {
    this.fetchStats();
  },

  methods: {
    fetchStats() {
      this.$store
        .dispatch("surveys/fetchStats")
        .then((stats) => {
          this.stats = stats;
        })
        .catch(() => {
          this.$toast.error("Error while fetching stats for reports.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
