<template>
  <v-data-table
    v-model="selected"
    :items="data"
    :headers="headers"
    :items-per-page.sync="perPage"
    :page.sync="page"
    :server-items-length="total"
    :loading="$loading('surveys/fetchCompleted')"
    :footer-props="$config.vuetify.vDataTable.footerProps"
    disable-sort
    show-select
    class="clickable"
    @update:page="fetchData"
    @update:items-per-page="fetchData"
    @click:row="(item) => $refs['modal' + item.id].open()"
  >
    <template v-slot:top>
      <div class="text--secondary mb-2">{{ $t("common.filters") }}:</div>
      <div class="mb-4 d-flex gap-20">
        <v-select
          v-for="(filter, i) in filterOptions"
          v-model="filters[i].answer_id"
          :key="filter.id"
          :label="filter.filter_label"
          :items="filter.answers"
          item-text="answer"
          item-value="id"
          filled
          rounded
          dense
          hide-details
          background-color="secondary"
          menu-props="offsetY"
          style="max-width: 200px"
          @change="
            resetData();
            fetchData();
          "
        />

        <!-- Reset filters -->
        <v-btn color="primary" rounded height="52" @click="resetFilters">
          {{ $t("common.reset") }}
        </v-btn>
      </div>
    </template>

    <template v-slot:footer.prepend>
      <!-- Export selected -->
      <v-btn
        color="primary"
        small
        :loading="!!$loading('surveys/downloadReport')"
        @click="downloadSelected"
      >
        <v-icon left>mdi-download</v-icon>
        {{ $t("survey.exportSelected") }} ({{ selected.length }})
      </v-btn>
    </template>

    <!-- Created_at -->
    <template v-slot:item.created_at="{ value }">
      {{ $utils.formatDate(value) }}
    </template>

    <!-- Suggestion -->
    <template v-slot:item.suggestion="{ value }">
      <div class="text-overflow-ellipsis max-1">
        {{ value }}
      </div>
    </template>

    <!-- Actions -->
    <template v-slot:item.actions="{ item }">
      <SurveyUserResultsModal
        :report="item"
        :ref="'modal' + item.id"
        invisible
      />

      <SurveyMenu :report="item" />
    </template>
  </v-data-table>
</template>

<script>
import SurveyMenu from "./SurveyMenu.vue";
import SurveyUserResultsModal from "./SurveyUserResultsModal.vue";

export default {
  components: { SurveyMenu, SurveyUserResultsModal },

  data() {
    return {
      data: [],
      page: 1,
      perPage: 10,
      total: null,
      selected: [],
      filters: [],
      filterOptions: [],
      headers: [
        {
          text: this.$t("common.date"),
          value: "created_at",
          width: "1%",
        },
        {
          text: this.$t("common.user"),
          value: "user.username",
          width: "1%",
        },
        {
          text: this.$t("survey.comment"),
          value: "suggestion",
        },
        { value: "actions", width: "0%" },
      ],
    };
  },

  created() {
    this.fetchData();
    this.fetchFilters();
  },

  methods: {
    fetchData() {
      const params = {
        page: this.page,
        per_page: this.perPage,
      };

      this.filters.forEach((filter, i) => {
        if (filter.answer_id) {
          params[`filters[${i}][question_id]`] = filter.question_id;
          params[`filters[${i}][answer_id]`] = filter.answer_id;
        }
      });

      this.$store
        .dispatch("surveys/fetchCompleted", params)
        .then((res) => {
          this.data = res.data;
          this.total = res.meta.total;
        })
        .catch(() => {
          this.$toast.error("Error while fetching completed surveys.");
        });
    },

    fetchFilters() {
      this.$store
        .dispatch("surveys/fetchFilters")
        .then((filters) => {
          this.filters = [];

          filters.forEach((filter) =>
            this.filters.push({
              question_id: filter.id,
              answer_id: null,
            })
          );

          this.filterOptions = filters;
        })
        .catch(() => this.$toast.error("Error while fetching filters."));
    },

    resetData() {
      this.data = [];
      this.page = 1;
      this.total = null;
    },

    resetFilters() {
      this.filters.forEach((filter) => (filter.answer_id = null));
      this.fetchData();
    },

    async downloadSelected() {
      for (const report of this.selected) {
        await this.$store
          .dispatch("surveys/downloadReport", report.id)
          .catch(() =>
            this.$toast.error(
              "Error while downloading survey report ID: " + report.id
            )
          );
      }

      this.selected = [];
    },
  },
};
</script>
